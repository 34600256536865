body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.default-row-spacer {
  margin-left: 100px;
  margin-top: -40px;
}

.row {
  margin-top: 1em;
  margin-bottom: 1em;
}

.c-header-nav-item {
  margin-right: 1em;
}

.hidden-element {
  display: none;
}

.progress-bar {
  background-color: #fff;
}

.btn {
  margin-top: 7px;
}

.row-title {
  margin-top: 12px;
}

.codeblock {
  padding: 1em;
}

.disabled-cursor {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

/* Small devices (landscape phones, 544px and down) */
@media (max-width: 544px) {
  .text-responsive-large {
    font-size: calc(50% + 1vw + 1vh);
  }

  .text-responsive-small {
    font-size: calc(10% + 1vw + 1vh);
  }
}
